import React from 'react'
import './TextSlide.scss'

const TextSlide = ({title, text, color}) => {

  return (
    <div id='TextSlide'>
      <div className={`container ${color}`}>
        <div className="slide-header">
          <span className="title">{title}</span>
        </div>
        <div className="slide-body">
          <span className="text">{text}</span>
        </div>
       </div>
    </div>
  )
}

export default TextSlide