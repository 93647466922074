import React, { useEffect, useState } from 'react';
import './CMSModal.scss';
import MDXSplitter from './MDXSplitter';

export default function DetailModal ({children, buttonText}) {
  const [isOpen, setOpen] = useState(false)
  const [preserveScrollY, setPreserveScrollY] = useState(0)

  useEffect(() => {
    if (isOpen && typeof document !== undefined) {
      setPreserveScrollY(window.scrollY)
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';

      document.body.style.width = '100vw';
    } else {
      if ( typeof document !== "undefined") {
        document.body.style.position = 'static';
        document.body.style.width = 'auto';
        window.scroll(0, preserveScrollY)
      }
    }
  }, [isOpen])



  return (
    <div className='CMSModal'>
      <button className="cms-modal-button" onClick={()=> setOpen(true)}> {buttonText} </button>
      { isOpen &&
        <div className="overlay">
          <div className='fact-box-container'>
            < div className='fact-body'>
              <MDXSplitter>{children}</MDXSplitter>
            </div>
            <button className="cms-modal-button" onClick={()=> setOpen(false)}>CLOSE</button>
          </div>
        </div>
      }
    </div>

  )
}