import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from 'react'
import { Helmet } from "react-helmet"
import Accordion from '../components/Accordion'
import CallOut from '../components/CallOut'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import CMSModal from '../components/CMSModal'
import FactBox from '../components/FactBox'
import FlipCard from '../components/FlipCard'
import FlipCardContainer from '../components/FlipCardContainer'
import HeaderWithImage from '../components/HeaderWithImage'
import HTTTYP from '../components/HTTTYP'
import LinkButton from '../components/LinkButton'
import Question from '../components/Question'
import SectionTemplate from '../components/SectionTemplate'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import TextCarousel from '../components/TextCarousel'
import TextSlide from '../components/TextSlide'

function Section({pageContext}) {
  const shortcodes = { 
    Accordion, FactBox, Carousel, Slide, Card, CardContainer, 
    ShowMore, HeaderWithImage, FlipCard, TextCarousel, TextSlide, Question,
    LinkButton, HTTTYP, FlipCardContainer, CMSModal, CallOut
  }
  const { section } = pageContext
  return (
    <div className="Section">
      <Helmet>
        <title>{section.frontmatter.sectionTitle}</title>
        <meta name="description" content={section.frontmatter.sectionTitle} />
      </Helmet>
      <SectionTemplate title={section.frontmatter.sectionTitle} path={section.slug} image={section.frontmatter.image}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {section.body}
          </MDXRenderer>
        </MDXProvider>
      </SectionTemplate>
    </div>
  )
}

export default Section;