import React, { useState } from 'react'
import './Question.scss'

const Question = ({title, setup, question, options, answerIntro, answer, nextHandler, showNextScenario}) => {

  const [picked, setPicked] = useState(null)

  function nextQuestion() {
    setPicked(null)
    nextHandler()
  }
  
  const buttons = options.map((option, idx) => {
    return (
      <button className="choice" key={idx} onClick={() => setPicked(idx)}>
        {option.option}
      </button>)
  })

  return (
    <div className='Question'>
      <h3 className="title">{title}</h3>
      <p className="setup">{setup}</p>
      <p className="question">{question}</p>
      { picked === null ?
        <div className="buttons">
          {buttons}
        </div>
      :
        <div className="show-answer">
          <p className="response">{options[picked].response}</p>
          <p className="explanation">{options[picked].explanation}</p>
          <p className="answer-intro">{answerIntro}</p>
          <p className="answer">{answer}</p>
        </div>
      }
      { (picked !== null) && showNextScenario &&
        <button onClick={nextQuestion} className="next">NEXT SCENARIO</button>
      }
    </div>
  )
}

export default Question;