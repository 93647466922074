import { Link } from 'gatsby'
import React from 'react'
import './LinkButton.scss'

const LinkButton = ({to, label, rounded}) => {
  const linkClass = rounded ? "link-container rounded" : "link-container"

  return (
    <div className='LinkButton'>
      <div className={linkClass}>
        <Link to={to}>{label}</Link>
      </div>
    </div>
  )
}

export default LinkButton