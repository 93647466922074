import React from 'react'
import './FlipCardContainer.scss'
import MDXSplitter from './MDXSplitter'


const FlipCardContainer = ({title, children}) => {

  return (
    <div id='FlipCardContainer'>
      {title &&
        <div className="title"><h2>{title}</h2></div>
      }
      <MDXSplitter>
        {children}
      </MDXSplitter>
    </div>
  )
}

export default FlipCardContainer