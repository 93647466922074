import React from 'react'
import './HTTTYP.scss'
import MDXSplitter from './MDXSplitter'

const HTTTYP = ({topic, image, children}) => {

  return (
    <div id='HTTTYP'>
      <div className="htttyp-header">
        <img src={image} alt="How to talk to your provider"/>
        <div className="titles">
          <h3>How to talk to your provider about</h3>
          <h2>{topic}</h2>
        </div>
      </div>
      <div className="content">
        <MDXSplitter>{children}</MDXSplitter>
      </div>
    </div>
  )

}

export default HTTTYP