import React, { useState } from 'react'
import './FlipCard.scss'
import MDXSplitter from './MDXSplitter'

const flipIcons = {
	'blue' : '/assets/flipcards/blue_tap_to_flip.svg',
	'light-blue' : '/assets/flipcards/light_blue_tap_to_flip.svg',
	'yellow' : '/assets/flipcards/yellow_tap_to_flip.svg',
	'orange' : '/assets/flipcards/orange_tap_to_flip.svg',
	'red' : '/assets/flipcards/red_tap_to_flip.svg',
	'light-purple' : '/assets/flipcards/light_purple_tap_to_flip.svg',
	'purple' : '/assets/flipcards/purple_tap_to_flip.svg',
	'default' : '/assets/flipcards/black_tap_to_flip.svg'
}

const FlipCard = ({color, title, size, children}) => {

  const [flip, setFlip] = useState(false);
	const flipClass = flip ? "container flip" : "container"

	const flipIcon = flipIcons[color] || flipIcons.default

  return (
    <div id='FlipCard' className={size}>
	    <div className={flipClass} role="button" tabIndex="0" onKeyPress={() => setFlip(!flip)} onClick={() => setFlip(!flip)}>
				<div className={`front ${color}`}>
				  <div className="dummy-element" />
				  <span className="title">{title} </span>
					<div className="footer">
						<span className="tap-to-flip">TAP TO FLIP</span>
					  <img src="/assets/flipcards/tap_to_flip.svg" alt="Tap to flip"/>
					</div>
				</div>
				<div className={`back ${color}`}>
				  <div className="dummy-element" />
				  <MDXSplitter>{children}</MDXSplitter>
					<img src={flipIcon} alt="Tap to flip"/>
				</div>
      </div>
	  </div>
  )
}

export default FlipCard