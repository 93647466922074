import React from 'react'
import './CallOut.scss'
import MDXSplitter from './MDXSplitter'

const CallOut = ({title, titleColor, borderColor, children}) => {
  return (
    <div className={`CallOut ${borderColor}`}>
      {title &&
        <h5 className={`title ${titleColor}`}>{title}</h5>
      }
      <div className='fact-body'>
        <MDXSplitter>{children}</MDXSplitter>
      </div>

    </div>
  )
}

export default CallOut